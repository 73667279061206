import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import { auth } from './auth.module';

import VuexPersist from 'vuex-persist';

const vuexSessionStorage = new VuexPersist({
    key: 'Vuex', // The key to store the state on in the storage provider.
    path: 'auth',
    storage: window.sessionStorage, // or window.sessionStorage or localForage
})

import VuexPersistedState from 'vuex-persistedstate';

const authState = VuexPersistedState({
  paths: ['user']
})

export default new Vuex.Store({
  modules: {
    auth,
    authState,
    plugins: [vuexSessionStorage.plugin]
  },
  plugins: [VuexPersistedState()],
});