<template>
  <section class="home">
    <div class="hero is-primary is-medium">
      <div class="hero-body">
        <div class="container has-text-centered">
          <p style="padding-bottom: 10px;"><img src="../assets/lock.svg" width="55.5px" height="55.5px"></p>
          <h1 class="title">
            Password-less Encryption Done Right.
          </h1>
          <h2 class="subtitle">
            {{ msg }}
          </h2>
        </div>
      </div>
    </div>
    <div class="additional-bar has-shadow">
      <div class="container">
        <div class="content has-text-centered">
          <!-- write content here -->
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="content">
              
              <center><iframe width="560" height="315" src="https://www.youtube.com/embed/NeKgWWcC4xE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></center>
              <br>
              <center><a href="#features"><b-icon class="big-icon" pack='fa' icon='arrow-circle-down'></b-icon></a></center>
        </div>
      </div>
    </div>
    <br>
    <br>
    <hr>
    <h1 id="features" class="title has-text-centered">FEATURES</h1>
    <hr>
    <div class="hero is-primary is-medium is-gray">
      <div class="hero-body">
        <div class="container has-text-centered">
          <p style="padding-bottom: 10px;"><b-icon class="big-icon" pack='fa' icon='shield'></b-icon></p>
          <h1 class="title">
            Industrial Strength Encryption
          </h1>
          <h2 class="subtitle">
            Protecting Your Data
          </h2>
        </div>
      </div>
    </div>
    <div class="hero is-primary is-medium is-white">
      <div class="hero-body">
        <div class="container has-text-centered">
          <p style="padding-bottom: 10px;"><b-icon class="big-icon" pack='fa' icon='user-secret'></b-icon></p>
          <h1 class="title">
            Ultimate Privacy
          </h1>
          <h2 class="subtitle">
            All data and private keys are processed and stored on your machine... not the Cloud!
          </h2>
        </div>
      </div>
    </div>
    <div class="hero is-primary is-medium">
      <div class="hero-body">
        <div class="container has-text-centered">
          <p style="padding-bottom: 10px;"><b-icon class="big-icon" pack='fa' icon='user-circle-o'></b-icon></p>
          <h1 class="title">
            User-Centric
          </h1>
          <h2 class="subtitle">
            You don't have to be a geek to encrypt and decrypt files!
          </h2>
        </div>
      </div>
    </div>
    <div class="hero is-primary is-medium is-white">
      <div class="hero-body">
        <div class="container has-text-centered">
          <p style="padding-bottom: 10px;"><b-icon class="big-icon" pack='fa' icon='retweet'></b-icon></p>
          <h1 class="title">
            Use WHATEVER you want to share your files
          </h1>
          <h2 class="subtitle">
            We are not another chat or file sharing service.
          </h2>
          <p class="org-description is-size-6">You let us help you encrypt your data and then you use what you want to share your files after.</p>
          <br>
          <center><img src="../assets/comm.svg"></center>
        </div>
      </div>
    </div>
    <div class="hero is-primary is-medium is-gray">
      <div class="hero-body">
        <div class="container has-text-centered">
          <p style="padding-bottom: 10px;"><b-icon class="big-icon" pack='fa' icon='info'></b-icon></p>
          <h1 class="title">
            Learn More
          </h1>
          <a href="about/#start"><button class="button search-button is-primary">Concept</button></a>
        </div>
      </div>
    </div>
    <br>
    <br>
    <div id="beta" class="beta-signup container has-text-centered is-off-white">
      <!-- Begin Mailchimp Signup Form -->
      <div id="mc_embed_signup">
      <form action="https://instacrypt.us2.list-manage.com/subscribe/post?u=d0b6e48e7564b974e1ed0f95f&amp;id=2e43e00014" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
          <div id="mc_embed_signup_scroll">
            <h2>Sign-Up here for a BETA invite when it's ready!</h2>
          <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
          <div class="mc-field-group">
            <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
          </label>
            <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
          </div>
          <div class="mc-field-group">
            <label for="mce-FNAME">First Name </label>
            <input type="text" value="" name="FNAME" class="" id="mce-FNAME">
          </div>
          <div class="mc-field-group">
            <label for="mce-LNAME">Last Name </label>
            <input type="text" value="" name="LNAME" class="" id="mce-LNAME">
          </div>
          <div id="mce-responses" class="clear">
            <div class="response" id="mce-error-response" style="display:none"></div>
            <div class="response" id="mce-success-response" style="display:none"></div>
          </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_d0b6e48e7564b974e1ed0f95f_2e43e00014" tabindex="-1" value=""></div>
            <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
          </div>
      </form>
      </div>
      <!--End mc_embed_signup-->
    </div>
    <br>
    <br>
  </section>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      msg: 'Encryption Simplified'
    }
  },
  method: {
    goToConcept: function() {
      this.$router.push({path: "/about/#start"});
    }
  }
}
</script>

<style lang="scss" scoped>
.additional-bar {
  padding: 15px;
}
.gh-btn {
  background-color: #eee;
  background-repeat: no-repeat;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  color: #333;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  white-space: nowrap;
  cursor: pointer;
}
.is-gray {
  background-color: #cccccc;
}
.is-white {
  background-color: #ffffff;
}
.is-off-white {
  background-color: #f5f5f5;
}
.big-icon {
  font-size: 48px;
}
#mc_embed_signup {
  background: #fff; 
  clear: center; 
  font: 14px Helvetica,Arial,sans-serif;
  width: 100%;
}
.beta-signup {
  text-align: center;
  align-content: center;
  width: 50%;
  padding-top: 0px;
  margin-top: 0px;
}
</style>

