import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    verify({ commit }, tk) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      console.log("verifying tk in module: " + tk);
      return AuthService.verify(api, tk).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          console.log("MODULE: ");
          console.log(user.status);
          console.log(user);
          if (user.status == true) {
            commit('loginSuccess', user);
            return Promise.resolve(user);
          } else {
            commit('loginFailure', user);
            return Promise.reject(user.message);
          }
          
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    send_reset({ commit }, user) {
      return AuthService.send_reset(user).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    search({ commit }, query) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      console.log("query in module: " + query);
      return AuthService.search(api, query).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    get_app_count({ commit }) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      return AuthService.get_app_count(api).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    get_con_count({ commit }) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      return AuthService.get_con_count(api).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    get_req_count({ commit }) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      return AuthService.get_req_count(api).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    change_first({ commit }, change) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      console.log("query in module: " + change);
      return AuthService.change_first(api, change).then(
        response => {
          commit('changeFirstSuccess', change);
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    change_last({ commit }, change) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      console.log("query in module: " + change);
      return AuthService.change_last(api, change).then(
        response => {
          console.log(change);
          commit('changeLastSuccess', change);
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    change_pass({ commit }, change) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      console.log("query in module: " + change);
      return AuthService.change_pass(api, change).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    change_plan({ commit }, change) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      console.log("query in module: " + change);
      return AuthService.change_plan(api, change).then(
        response => {
          console.log("AUTH MODULE: ");
          console.log(response.data.status);
          console.log(response.data.plan);
          if (response.data.status == true) {
            commit('changePlanSuccess', response.data.plan);
          }
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    send_invite({ commit }, uid) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      return AuthService.send_invite(api, uid).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    get_requests({ commit }) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      return AuthService.get_requests(api).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    get_contacts({ commit }) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      return AuthService.get_contacts(api).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    accept_req({ commit }, uid) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      return AuthService.accept_request(api, uid).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    remove_req({ commit }, uid) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      return AuthService.remove_request(api, uid).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    invite_user({ commit }, mail) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      return AuthService.invite_user(api, mail).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    gen_secret({ commit }, a) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      return AuthService.gen_app_access(api, a).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    remove_secret({ commit }, id) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      return AuthService.remove_app_access(api, id).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    get_app_access({ commit }) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      return AuthService.get_app_access(api).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    get_billing({ commit }) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      return AuthService.get_billing(api).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    get_portal({ commit }) {
      var api = process.env.VUE_APP_API;
      console.log("api in module: " + api);
      return AuthService.get_portal(api).then(
        response => {
          return Promise.resolve(response);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    changeFirstSuccess(state, change) {
      state.user.account.first = change;
    },
    changeLastSuccess(state, change) {
      state.user.account.last = change;
    },
    changePlanSuccess(state, change) {
      state.user.account.plan = change;
    },
  },
};