import authHeader from './auth.header';

class AuthService {
    verify(api, tk) {
        console.log("tk variable inside class: " + tk);
        axios.defaults.headers.common['Authorization'] = "Bearer " + tk;
        return axios
        .post(api + '/v1/user/verify')
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    login(user) {
        return axios
        .post(user.api + '/v1/user/login', {
            email: user.email,
            password: user.password
        })
        .then(response => {
            console.log("Service Status: ")
            console.log(response.data.status);
            console.log(response.data);
            if (response.data.status == true) {
                if (response.data.account.token) {
                    localStorage.setItem('user', JSON.stringify(response.data.account));
                }
            }
            return response.data;
        });
    }
    
    logout() {
      localStorage.removeItem('user');
      return {
          data: {
              status: true
          }
      } 
    }

    send_reset(user) {
        return axios
        .post(user.api + '/v1/user/send/reset', {
            email: user.email,
        })
        .then(response => {
            console.log(response);
            return response.data;
        });
    }

    search(api, query) {
        console.log("query variable inside class: " + query);
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .post(api + '/v1/user/search', {
            email: query
        })
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    get_app_count(api) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .get(api + '/v1/access/count')
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    get_con_count(api) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .get(api + '/v1/contact/get/contacts/count')
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    get_req_count(api) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .get(api + '/v1/contact/get/requests/count')
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    change_first(api, change) {
        console.log("query variable inside class: " + change);
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .post(api + '/v1/user/change/first', {
            first: change
        })
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    change_last(api, change) {
        console.log("query variable inside class: " + change);
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .post(api + '/v1/user/change/last', {
            last: change
        })
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    change_pass(api, change) {
        console.log("query variable inside class: " + change);
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .post(api + '/v1/user/change/pass', {
            password: change
        })
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    change_plan(api, change) {
        console.log("query variable inside class: " + change);
        if (change != "1" && change != "2" && change != "0") {
            axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
            return axios
            .post(api + '/v1/user/change/plan', {
                paid: change
            })
            .then(response => {
                console.log(response.data);
                return response.data;
            });
        } else {
            axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
            return axios
            .post(api + '/v1/user/change/plan', {
                plan: change
            })
            .then(response => {
                console.log(response.data);
                return response.data;
            });
        }
    }

    send_invite(api, uid) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .post(api + '/v1/contact/add', {
            id: uid
        })
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    get_requests(api) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .get(api + '/v1/contact/get/requests')
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    get_contacts(api) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .get(api + '/v1/contact/get/contacts')
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    accept_request(api, uid) {
        console.log("Accepting contact/request in class: " + uid);
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .post(api + '/v1/contact/accept', {
            id: uid
        })
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    remove_request(api, uid) {
        console.log("Removing contact/request in class: " + uid);
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .post(api + '/v1/contact/remove', {
                id: uid
        })
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    invite_user(api, mail) {
        console.log("Sending invite to user: " + mail);
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .post(api + '/v1/user/invite', {
            email: mail
        })
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    gen_app_access(api, a) {
        console.log("Generating secret in class: " + a);
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .post(api + '/v1/access/add', {
            alias: a
        })
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    get_app_access(api) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .get(api + '/v1/access/get')
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    remove_app_access(api, id) {
        console.log("Removing secret in class: " + id);
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .post(api + '/v1/access/remove', {
                sid: id
        })
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    get_billing(api) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .get(api + '/v1/payment/invoices')
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    get_portal(api) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + authHeader();
        return axios
        .get(api + '/v1/payment/portal')
        .then(response => {
            console.log(response.data);
            return response.data;
        });
    }
    
  }
  
  export default new AuthService();