import { extend } from "vee-validate";
import { min, max, required, confirmed, length, email, regex } from "vee-validate/dist/rules";

// Custom Rules Definition
console.log(process.env.VUE_APP_API);
const checkmail = value => {
    return axios.post(process.env.VUE_APP_API + "/v1/user/check", { email: value }, { useCredentials: true }).then((res) => {
        console.log(res.data.data.message);
        return {
          valid: res.data.data.status,
          data: {
            message: res.data.data.message
          }
        }
    }).catch((error) => {
        var msg = "Can't connect to server to verify..."
        console.log(msg)
        console.log(error)
        return {
          valid: false,
          data: {
            message: msg
          }
        }
    });
}

// Define Rules to Use
extend("min", {
  ...min,
  message: "Not enough characters yet"
});

extend("max", {
  ...max,
  message: "Too many characters"
});

extend("required", {
  ...required,
  message: "This field is required"
});

extend("email", {
  ...email,
  message: "This field must be a valid email"
});

extend("confirmed", {
  ...confirmed,
  message: "Passwords do not match"
});

extend("length", {
  ...length,
  message: "This field must have 2 options"
});

extend("regex", {
  ...regex,
  message: "Not strong enough yet..."
});

extend("checkmail", {
  validate: checkmail,
  message: "This e-mail is already registered with us.",
  computesRequired: true
});

