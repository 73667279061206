import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import VuePureLightbox from "vue-pure-lightbox";
import axios from 'axios';

window.axios = require('axios');
axios.defaults.headers.common['Content-Type'] = "application/json";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
axios.defaults.headers.common['Accept'] = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

import "./vee-validate";

import styles from 'vue-pure-lightbox/dist/VuePureLightbox.css';

import  VueSidebarMenu from 'vue-sidebar-menu';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
Vue.use(VueSidebarMenu);


import Buefy from 'buefy';

Vue.use(Buefy, {
  defaultIconPack: 'fa'
})
Vue.use(styles)

import "font-awesome/scss/font-awesome.scss";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading, {
	// props
	color: '#aac734',
	fullPage: false,
	canCancel: false
});

import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { 
	siteKey: process.env.VUE_APP_GOOGLE_SITE_KEY,
	loaderOptions: {
    useRecaptchaNet: true
  }
})

import store from './store';

Vue.config.productionTip = false;

console.log = function () {};

new Vue({
	el: "#app",
	router,
	store,
	components: {
		VuePureLightbox
	},
	render: h => h(App)
}).$mount('#app')
