import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pricing.vue')
  },
  {
    path: '/documentation/setup',
    name: 'Document_Setup',
    component: () => import(/* webpackChunkName: "doc-setup" */ '../views/documentation/SetUp.vue')
  },
  {
    path: '/documentation/dashboard',
    name: 'Document_Dashboard',
    component: () => import(/* webpackChunkName: "doc-dashboard" */ '../views/documentation/Dashboard.vue')
  },
  {
    path: '/documentation/app',
    name: 'Document_App',
    component: () => import(/* webpackChunkName: "doc-app" */ '../views/documentation/Desktop.vue')
  },
  {
    path: '/documentation/windows',
    name: 'Document_Windows',
    component: () => import(/* webpackChunkName: "doc-windows" */ '../views/documentation/Windows.vue')
  },
  {
    path: '/documentation/mac',
    name: 'Document_Mac',
    component: () => import(/* webpackChunkName: "doc-mac" */ '../views/documentation/Mac.vue')
  },
  {
    path: '/documentation/linux',
    name: 'Document_Linux',
    component: () => import(/* webpackChunkName: "doc-linux" */ '../views/documentation/Linux.vue')
  },
  {
    path: '/choose/:inv/:mail',
    name: 'ChoosePlan',
    component: () => import(/* webpackChunkName: "choose-plan" */ '../views/ChoosePlan.vue'),
    props: true
  },
  {
    path: '/change/:type/:token',
    name: 'ChangePlan',
    component: () => import(/* webpackChunkName: "change-plan" */ '../views/ChangePlan.vue'),
    props: true
  },
  {
    path: '/signup/:planid/:inv/:mail',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "signup" */ '../views/SignUp.vue'),
    props: true
  },
  {
    path: '/signupdone/:status/:trans?',
    name: 'SignUpDone',
    component: () => import(/* webpackChunkName: "signupdone" */ '../views/SignUpDone.vue'),
    props: true
  },
  {
    path: '/payment/:type/:pid/:email/:token',
    name: 'Payment',
    component: () => import(/* webpackChunkName: "payment" */ '../views/Payment.vue'),
    props: true
  },
  {
    path: '/verify/:tk',
    name: 'Verify',
    component: () => import(/* webpackChunkName: "verify" */ '../views/Verify.vue'),
    props: true
  },
  {
    path: '/login/:next?',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    props: true
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue')
  },
  {
    path: '/send/reset',
    name: 'SendReset',
    component: () => import(/* webpackChunkName: "SendReset" */ '../views/SendReset.vue')
  },
  {
    path: '/send/resetdone/:status',
    name: 'SendResetDone',
    component: () => import(/* webpackChunkName: "SendResetDone" */ '../views/SendResetDone.vue'),
    props: true
  },
  {
    path: '/reset/:tk',
    name: 'Reset',
    component: () => import(/* webpackChunkName: "Reset" */ '../views/Reset.vue'),
    props: true
  },
  {
    path: '/resetdone/:status',
    name: 'ResetDone',
    component: () => import(/* webpackChunkName: "ResetDone" */ '../views/ResetDone.vue'),
    props: true
  },
  {
    path: '/beta/:plan?',
    name: 'Beta',
    component: () => import(/* webpackChunkName: "Beta" */ '../views/Beta.vue'),
    props: true
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../dashboard/Dashboard.vue'),
      children: [
        {
          path: 'home',
          name: 'MyHome',
          component: () => import('../dashboard/components/Home.vue')
        },
        {
          path: 'profile/:change/:payment?',
          name: 'MyProfile',
          component: () => import('../dashboard/components/Profile.vue'),
          props: true
        },
        {
          path: 'billing',
          name: 'MyBilling',
          component: () => import('../dashboard/components/Billing.vue')
        },
        {
          path: 'appaccess',
          name: 'MyApp',
          component: () => import('../dashboard/components/AppAccess.vue')
        },
        {
          path: 'contacts',
          name: 'MyContacts',
          component: () => import('../dashboard/components/Contacts.vue')
        },
        {
          path: 'requests',
          name: 'MyRequests',
          component: () => import('../dashboard/components/Requests.vue')
        },
        {
          path: 'search',
          name: 'MySearch',
          component: () => import('../dashboard/components/Search.vue'),
            children: [
              {
                path: 'results/:q',
                name: 'SearchResults',
                component: () => import('../dashboard/components/SearchResults'),
                props: true
              }
            ]
        },
        {
          path: 'invite/:mail?',
          name: 'MyInvite',
          props: true,
          component: () => import('../dashboard/components/Invite.vue'),
            children: [
              {
                path: 'results/:q',
                name: 'InviteResults',
                component: () => import('../dashboard/components/InviteResults'),
                props: true
              }
            ]
        },
        {
          path: 'redirect/:section/:status',
          name: 'Redirect',
          component: () => import('../dashboard/components/Redirect.vue'),
          props: true
        }
      ]
  },
  {
    path: '/tos',
    name: 'TermsOfService',
    component: () => import(/* webpackChunkName: "terms-of-service" */ '../views/TermsOfService.vue')

  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')

  },
  {
    path: '/navbar/:status',
    name: 'NavBar',
    component: () => import(/* webpackChunkName: "navbar" */ '../components/NavBar.vue')

  },
  {
    // will match everything
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue')

  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
