<template>
  <footer class="foot-bar">
    <div class="container">
      <div class="content">
        <div class="columns">
          <div class="column has-text-centered">
            <h4>Powered By:</h4>
             <a href="https://3df.io">
             <p><img src="../assets/3df-logo.png" width="60px">
             <br>
             3DF Limited</p>
             </a>
            <p>
              <a href="mailto://locker@3df.io"><b-icon pack="fa" icon="envelope"></b-icon></a>
              <a href="https://facebook.com/3dfhk" target="_blank"><b-icon pack="fa" icon="facebook-square"></b-icon></a>
              <a href="https://instagram.com/3dfhk" target="_blank"><b-icon pack="fa" icon="instagram"></b-icon></a>
            </p>
          </div>
        </div>
          <div class="column has-text-centered">
          </div>
        <div class="columns has-text-centered">
          <div class="column">  
            <p>
              <a href="https://3df.io" target="_blank">3DF Limited</a>&nbsp;&copy; All Rights Reserved, 2020-2025
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'foot-bar'
}
</script>

<style lang="scss" scoped>
.foot-bar {
  padding-top: 30px;
}
</style>
