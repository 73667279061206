<template>
  <section class="navbar hero app-navbar is-bold">
    <!-- <div class="hero-head">
      <header class="navbar is-bold"> -->
        <div class="container">
        <div class="navbar-brand">
          <a class="navbar-item" href="/">
            <!-- <i class="fa fa-cog fa-spin fa-2x fa-fw"></i> --> 
            <img src="../assets/logo.svg" style="padding-right: 5px;"><p style="padding-right: 40px;">InstaCrypt<sup style="color: #aac734; font-size: 8px;">ALPHA</sup></p>
            <!-- ref: http://fontawesome.io/examples/#animated -->
          </a>
          <a class="navbar-item is-hidden-desktop" href="https://github.com/3dfosi/ic-desktop" target="_blank">
            <b-icon pack="fa" icon="github" type="is-black"></b-icon>
          </a>
          <div class="navbar-burger burger" data-target="navbarDropdown">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div id="navbarDropdown" class="navbar-menu">
          <div class="navbar-start">
            <a class="navbar-item" href="#">
              <router-link :to="{ name: 'Home' }">Home</router-link>
            </a>
            <a class="navbar-item" href="#">
              <router-link :to="{ name: 'About' }">Concept</router-link>
            </a>
            <a class="navbar-item" href="#">
              <router-link :to="{ name: 'Pricing' }">Pricing</router-link>
            </a>
            <div class="menu-separator">
            </div>
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link" href="#">
                Download
              </a>
              <div class="navbar-dropdown is-boxed">
                <a class="navbar-item" href="https://github.com/3dfosi/ic-desktop/releases/download/v0.2.0/InstaCrypt-v0.2.0-x64-Win10-exeInstall.zip">
                  Windows
                </a>
                <a class="navbar-item" href="https://github.com/3dfosi/ic-desktop/releases/download/v0.2.0/InstaCrypt-v0.2.0-x64-MacOS.dmg">
                  Mac
                </a>
                <a class="navbar-item" href="https://github.com/3dfosi/ic-desktop/releases/download/v0.2.0/InstaCrypt-v0.2.0-x64-Linux.tar.bz2">
                  Linux
                </a>
              </div>
            </div>
            <div class="navbar-item has-dropdown is-hoverable close-on-click">
              <a class="navbar-link" href="#">
                User Guides
              </a>
              <div class="navbar-dropdown is-boxed">
                <router-link :to="{ name: 'Document_Setup' }">
                  <a class="navbar-item" href="#">
                    Setup
                  </a>
                </router-link>
                <hr class="navbar-divider">
                <router-link :to="{ name: 'Document_Dashboard' }">
                  <a class="navbar-item" href="#">
                    Vault
                  </a>
                </router-link>
                <hr class="navbar-divider">
                <router-link :to="{ name: 'Document_App' }">
                  <a class="navbar-item" href="#">
                    App
                  </a>
                </router-link>
              </div>
            </div>
          </div>

          <div class="navbar-end">
            <!--<div class="navbar-item">-->
              <a class="navbar-item" href="https://github.com/3dfosi/ic-desktop" target="_blank">
                <b-icon pack="fa" icon="github" type="is-black"></b-icon>
              </a>
            <!--</div>-->
            <div class="navbar-item">
              <div class="field is-grouped">
                <p class="control">
                  <router-link :to='this.link1' >
                    <a class="button is-primary" href="#">
                      <span class="lock-icon" v-if="this.state"><b-icon pack='fa' icon='lock'></b-icon></span>
                      <span>{{ this.button1 }}</span>
                    </a>
                  </router-link>
                </p>
                <p class="control">
                  <router-link :to='this.link2'>
                    <a class="button is-primary is-outlined" href="#">
                      <b-icon pack="fa" icon="user"></b-icon>
                      <span>{{ this.button2 }}</span>
                    </a>
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
        </div>
    <!--  </header>
    </div> -->
  </section>
</template>

<script>
// import { NavBar } from '.';
export default {
  props: { 
    'status': {
        type: String,
        default: "0"
    } 
  },
  computed: {
    state() {
      return this.$store.state.auth.status.loggedIn
    },
    button1() {
      if (this.state == true) {
        return "My Vault";
      } else {
        return "Sign Up";
      }
    },
    button2() {
      if (this.state == true) {
        return "Logout";
      } else {
        return "Login";
      }
    },
    link1() {
      if (this.state ==  true) {
        return "/dashboard/home";
      } else {
        return "/beta";
      }
    },
    link2() {
      if (this.state == true) {
        return "/logout";
      } else {
        return "/login";
      }
    }
  }
}
/* burger navigation */
document.addEventListener('DOMContentLoaded', function () {
  // Get all "navbar-burger" elements
  var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)
  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach(function ($el) {
      $el.addEventListener('click', function () {
        // Get the target from the "data-target" attribute
        var target = $el.dataset.target
        var $target = document.getElementById(target)
        // Toggle the class on both the "navbar-burger" and the "navbar-menu"
        $el.classList.toggle('is-active')
        $target.classList.toggle('is-active')
      })
    })
  }
})
</script>

<style lang="scss" scoped>
.navbar {
  border-bottom: 1px solid #e0e0e0;
  position: absolute;
  width: 100%;
}
.lock-icon {
  padding-right: 10px;
}
.menu-separator {
  padding-left: 20px;
}
</style>
