<template>
  <section class="info-bar">
    <div class="hero is-dark">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            <center><b>Support Us!</b></center>
          </h1>
          <h2 class="subtitle">
              <br>
              <center>
              <!-- Place this tag where you want the button to render. -->
              <a class="github-button" href="https://github.com/hkdb/vuejs-quickstart" data-size="large" data-show-count="true" aria-label="Star hkdb/vuejs-quickstart on GitHub">
                Star
              </a>
              &nbsp;&nbsp;&nbsp;
              <!-- Place this tag where you want the button to render. -->
              <a class="github-button" href="https://github.com/hkdb/vuejs-quickstart" data-size="large" aria-label="Fork hkdb/vuejs-quickstart on GitHub">
                Fork
              </a>
              </center>
          </h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'info-bar'
}
</script>

<style lang="scss" scoped>
.hero-body {
  background-color: #cccccc;
}
</style>
